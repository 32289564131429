import Ladies from './Ladies.vue'
import LadiesCreate from './Create.vue'
import LadiesShow from './Show.vue'

export default [
    {
        path: '/ladies',
        name: 'Ladies',
        component: Ladies
    },
    {
        path: '/ladies/create',
        name: 'LadiesCreate',
        component: LadiesCreate
    },
    {
        path: '/ladies/show/:id',
        name: 'LadiesShow',
        component: LadiesShow
    },
    {
        path: '/ladies/edit/:id',
        name: 'LadiesEdit',
        component: LadiesCreate
    },
]
