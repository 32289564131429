<template>
  <div class="card">
    <div class="card-header">
      <h4>Ladies Price</h4>
    </div>
    <div class="card-body">
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="id"
        :search="search"
        hide-default-footer
        :page.sync="page"
        :items-per-page="itemsPerPage"
        class="elevation-1"
        @page-count="pageCount = $event"
      >
        <template v-slot:item="row">
          <tr>
            <td>{{ row.item.type_name }}</td>
            <td>Rp. {{ formatPrice(row.item.company_fee) }}</td>
            <td>Rp. {{ formatPrice(row.item.fee_ladies) }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "Type Name",
          value: "type_name",
        },
        {
          text: "Company Fee",
          value: "company_fee",
        },
        {
          text: "Fee Ladies",
          value: "fee_ladies",
        },
      ];
    },
  },
};
</script>
