<template>
  <div class="card">
    <div class="card-header">
      <h4>Detail Ladies</h4>
    </div>
    <div class="card-body">
      <div class="form-group">
        <label>Name</label>
        <h5>{{ data.name }}</h5>
      </div>
      <div class="form-group">
        <label>Category Name</label>
        <h5>{{ data.category_name }}</h5>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["data"],
  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
