<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Ladies</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="ladiesInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>Name</label>
                  <input type="text" class="form-control" v-model="form.name" />
                  <small class="text-danger" v-if="errors.name">{{
                    errors.name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Category</label>
                  <model-list-select
                    :list="categories"
                    v-model="form.category_id"
                    option-value="id"
                    option-text="name"
                    placeholder="Select Category"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.category_id">{{
                    errors.category_id[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  type="submit"
                  :disabled="isSubmitting"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import swal from "sweetalert";
import { apiGet } from "../../services/api";

export default {
  name: "FormLadies",
  data() {
    return {
      name: "Form Ladies",
      form: {
        name: "",
        category_id: "",
      },
      categories: [],
      errors: {},
      isSubmitting: false,
      edit: false,
    };
  },
  mounted() {
    this.getData();
    this.getCategories();
  },
  methods: {
    getData() {
      let id = this.$route.params.id;
      apiGet("ladies/" + id).then((result) => {
        this.form = result.data.data;
        this.edit = true;
      });
    },
    getCategories() {
      apiGet("ladies_category").then((result) => {
        this.categories = result.data.data;
      });
    },
    ladiesInsert() {
      this.isSubmitting = true;
      if (this.edit == false) {
        axios
          .post(this.$store.state.api + "ladies/store", this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Ladies has been saved!");
            this.$router.push({ name: "Ladies" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      } else {
        let id = this.$route.params.id;
        axios
          .post(this.$store.state.api + "ladies/update/" + id, this.form, {
            headers: {
              Authorization: this.$store.state.token,
            },
          })
          .then(() => {
            this.$noty.success("Your Ladies has been saved!");
            this.$router.push({ name: "Ladies" });
          })
          .catch((error) => {
            this.errors = error.response.data;
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
