<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>
    <section class="body">
      <h2 class="section-title">{{ name }}</h2>
      <v-progress-circular
        v-if="loading"
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div v-else class="row">
        <div class="col-md-4">
          <ladies-detail :data="ladies"></ladies-detail>
        </div>
        <div class="col-md-8">
          <ladies-price :data="ladies.price"></ladies-price>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { apiGet } from "../../services/api";
import LadiesDetail from "./LadiesDetail.vue";
import LadiesPrice from "./LadiesPrice.vue";

export default {
  name: "Ladies",
  components: { LadiesDetail, LadiesPrice },
  data() {
    return {
      name: "Ladies",
      ladies: [],
      loading: true,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      let id = this.$route.params.id;
      apiGet("ladies/" + id).then((result) => {
        this.ladies = result.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
